var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "schedule-meeting-container" }, [
      _c("div", { staticClass: "schedular-wrapper" }, [
        _c("div", { staticClass: "meeting-guide" }, [
          _vm._v(
            "\n        Set days and time for Standups, Review Sessions and other Recurring\n        Meetings\n      "
          ),
        ]),
        _c("div", { staticClass: "standups-container" }, [
          _c("div", { staticClass: "box-title" }, [_vm._v("Daily Standups")]),
          _c("div", { staticClass: "box-info-setup" }, [
            _vm._v(
              "\n          Set a time for daily standups. We recommend that standups are done\n          every workday so everyone can be on the same page.\n        "
            ),
          ]),
          _c("div", { staticClass: "form-session-div" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.standup_days,
                  expression: "form.standup_days",
                },
              ],
              staticClass: "session-input-text",
              attrs: { type: "text" },
              domProps: { value: _vm.form.standup_days },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "standup_days", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-session-div form-row" }, [
            _c("div", { staticClass: "session-textbox-left" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.standup_interval,
                    expression: "form.standup_interval",
                  },
                ],
                staticClass: "session-input-text",
                attrs: { type: "text" },
                domProps: { value: _vm.form.standup_interval },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "standup_interval", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.standup_time,
                    expression: "form.standup_time",
                  },
                ],
                staticClass: "session-input-text",
                attrs: { type: "text" },
                domProps: { value: _vm.form.standup_time },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "standup_time", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "form-session-div" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.standup_attendees,
                  expression: "form.standup_attendees",
                },
              ],
              staticClass: "session-input-text",
              attrs: { type: "text" },
              domProps: { value: _vm.form.standup_attendees },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "standup_attendees", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "sprint-planning-box" }, [
          _c("div", { staticClass: "box-title" }, [
            _vm._v("Sprint Planning Sessions"),
          ]),
          _c("div", { staticClass: "box-info-setup" }, [
            _vm._v(
              "\n          Set a time for sprint planning sessions. We recommend that this is\n          done on the first day of every work week.\n        "
            ),
          ]),
          _c("div", { staticClass: "form-session-div" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.sprint_days,
                  expression: "form.sprint_days",
                },
              ],
              staticClass: "session-input-text",
              attrs: { type: "text" },
              domProps: { value: _vm.form.sprint_days },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "sprint_days", $event.target.value)
                },
              },
            }),
          ]),
          _c("div", { staticClass: "form-session-div form-row" }, [
            _c("div", { staticClass: "session-textbox-left" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.sprint_interval,
                    expression: "form.sprint_interval",
                  },
                ],
                staticClass: "session-input-text",
                attrs: { type: "text" },
                domProps: { value: _vm.form.sprint_interval },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "sprint_interval", $event.target.value)
                  },
                },
              }),
            ]),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.sprint_time,
                    expression: "form.sprint_time",
                  },
                ],
                staticClass: "session-input-text",
                attrs: { type: "text" },
                domProps: { value: _vm.form.sprint_time },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "sprint_time", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "form-session-div" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.sprint_attendees,
                  expression: "form.sprint_attendees",
                },
              ],
              staticClass: "session-input-text",
              attrs: { type: "text" },
              domProps: { value: _vm.form.sprint_attendees },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "sprint_attendees", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._m(0),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sprint-review-box" }, [
      _c("div", { staticClass: "box-title" }, [
        _vm._v("Sprint Review & Demo Sessions"),
      ]),
      _c("div", { staticClass: "box-info-setup" }, [
        _vm._v(
          "\n          Set a time for sprint review & demos. We recommend that this is done\n          on the last day of every work week.\n        "
        ),
      ]),
      _c("div", { staticClass: "form-session-div" }, [
        _c("input", {
          staticClass: "session-input-text",
          attrs: { type: "text", placeholder: "Select days" },
        }),
      ]),
      _c("div", { staticClass: "form-session-div form-row" }, [
        _c("div", { staticClass: "session-textbox-left" }, [
          _c("input", {
            staticClass: "session-input-text",
            attrs: { placeholder: "Repeat", type: "text" },
          }),
        ]),
        _c("div", [
          _c("input", {
            staticClass: "session-input-text",
            attrs: { type: "text", placeholder: "Select time" },
          }),
        ]),
      ]),
      _c("div", { staticClass: "form-session-div" }, [
        _c("input", {
          staticClass: "session-input-text",
          attrs: { type: "text", placeholder: "Select attendees" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }