<template>
  <div>
    <div class="schedule-meeting-container">
      <div class="schedular-wrapper">
        <div class="meeting-guide">
          Set days and time for Standups, Review Sessions and other Recurring
          Meetings
        </div>
        <div class="standups-container">
          <div class="box-title">Daily Standups</div>
          <div class="box-info-setup">
            Set a time for daily standups. We recommend that standups are done
            every workday so everyone can be on the same page.
          </div>
          <div class="form-session-div">
            <input
              type="text"
              v-model="form.standup_days"
              class="session-input-text"
            />
          </div>
          <div class="form-session-div form-row">
            <div class="session-textbox-left">
              <input
                type="text"
                v-model="form.standup_interval"
                class="session-input-text"
              />
            </div>
            <div>
              <input
                type="text"
                v-model="form.standup_time"
                class="session-input-text"
              />
            </div>
          </div>
          <div class="form-session-div">
            <input
              type="text"
              v-model="form.standup_attendees"
              class="session-input-text"
            />
          </div>
        </div>
        <div class="sprint-planning-box">
          <div class="box-title">Sprint Planning Sessions</div>
          <div class="box-info-setup">
            Set a time for sprint planning sessions. We recommend that this is
            done on the first day of every work week.
          </div>
          <div class="form-session-div">
            <input
              type="text"
              v-model="form.sprint_days"
              class="session-input-text"
            />
          </div>
          <div class="form-session-div form-row">
            <div class="session-textbox-left">
              <input
                type="text"
                v-model="form.sprint_interval"
                class="session-input-text"
              />
            </div>
            <div>
              <input
                type="text"
                v-model="form.sprint_time"
                class="session-input-text"
              />
            </div>
          </div>
          <div class="form-session-div">
            <input
              type="text"
              v-model="form.sprint_attendees"
              class="session-input-text"
            />
          </div>
        </div>
        <div class="sprint-review-box">
          <div class="box-title">Sprint Review & Demo Sessions</div>
          <div class="box-info-setup">
            Set a time for sprint review & demos. We recommend that this is done
            on the last day of every work week.
          </div>
          <div class="form-session-div">
            <input
              type="text"
              placeholder="Select days"
              class="session-input-text"
            />
            <!-- :items="toolsAndFrameworks"
              v-model="form.tools_lang"
              item-value="toolsAndFrameworks.name"
              item-text="name" -->
            <!-- <v-combobox
              class="demo_days"
              flat
              multiple
              persistent-hint
              small-chips
              solo
              placeholder="Select days"
            ></v-combobox> -->
          </div>
          <div class="form-session-div form-row">
            <div class="session-textbox-left">
              <input
                placeholder="Repeat"
                type="text"
                class="session-input-text"
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="Select time"
                class="session-input-text"
              />
            </div>
          </div>
          <div class="form-session-div">
            <input
              type="text"
              placeholder="Select attendees"
              class="session-input-text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({
    form: {
      standup_days: "Monday, Tuesday, Wednseday, Thursday, Friday",
      standup_interval: "Every week",
      standup_time: "3:00pm WAT",
      standup_attendees: "Everyone",
      sprint_days: "Monday",
      sprint_interval: "Every week",
      sprint_time: "3:00pm WAT",
      sprint_attendees: "Everyone",
    },
  }),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.schedule-meeting-container {
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.schedular-wrapper {
  padding-top: 32px;
  width: 564px;
  text-align: left;
  padding-bottom: 100px;
}
.meeting-guide {
  font-size: 18px;
  line-height: 140%;
  color: #1b1e22;
  margin-bottom: 16px;
}
.sprint-review-box,
.sprint-planning-box,
.standups-container {
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
}
.box-title {
  font-weight: bold;
  font-size: 18px;
  line-height: 140%;
  color: #1b1e22;
}
.box-info-setup {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 24px;
}
.session-input-text {
  background: #f7f7f8;
  border-radius: 4px;
  width: 100%;
  height: 50px;
  padding: 16px;
}
.form-session-div.form-row input {
  width: 246px;
}
.session-textbox-left {
  margin-right: 24px;
}
.session-input-text:focus {
  outline: 0;
  border: 0px;
}
.session-input-text:hover {
}
.form-session-div {
  display: flex;
  margin-top: 24px;
}
</style>
<style>
.demo_days .v-input__slot {
  background: #f7f7f8;
  /* border: 1px solid #e4e5e7; */
  box-sizing: border-box;
  border-radius: 4px;
}
.demo_days .v-input__slot:focus {
}
</style>
